<template>
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.25245 0.671175C3.62753 0.296102 4.13623 0.0853882 4.66667 0.0853882H7.79C8.31257 0.0854532 8.81444 0.290046 9.18808 0.655388L11.398 2.81664C11.398 2.81668 11.398 2.8166 11.398 2.81664C11.5885 3.00286 11.74 3.22537 11.8433 3.47089C11.9467 3.71645 12 3.9802 12 4.24664V10.0854C12 10.6158 11.7893 11.1245 11.4142 11.4996C11.0391 11.8747 10.5304 12.0854 10 12.0854H9.33333V12.7521C9.33333 13.2825 9.12262 13.7912 8.74755 14.1663C8.37247 14.5413 7.86377 14.7521 7.33333 14.7521H2C1.46957 14.7521 0.960859 14.5413 0.585786 14.1663C0.210714 13.7912 0 13.2825 0 12.7521V5.41872C0 4.88829 0.210713 4.37958 0.585786 4.00451C0.960859 3.62944 1.46957 3.41872 2 3.41872H2.66667V2.08539C2.66667 1.55495 2.87738 1.04625 3.25245 0.671175ZM4 4.08539V10.0854C4 10.2622 4.07024 10.4318 4.19526 10.5568C4.32029 10.6818 4.48986 10.7521 4.66667 10.7521H10C10.1768 10.7521 10.3464 10.6818 10.4714 10.5568C10.5964 10.4318 10.6667 10.2622 10.6667 10.0854V4.2468C10.6667 4.24678 10.6667 4.24683 10.6667 4.2468C10.6666 4.15802 10.6489 4.07005 10.6144 3.98822C10.58 3.90636 10.5295 3.83222 10.466 3.77014L8.25592 1.60872C8.2559 1.60871 8.25593 1.60874 8.25592 1.60872C8.1314 1.48699 7.96414 1.41876 7.79 1.41872C7.78997 1.41872 7.79003 1.41872 7.79 1.41872H4.66667C4.48986 1.41872 4.32029 1.48896 4.19526 1.61398C4.07024 1.73901 4 1.90858 4 2.08539V4.08539ZM8 12.0854H4.66667C4.13623 12.0854 3.62753 11.8747 3.25245 11.4996C2.87738 11.1245 2.66667 10.6158 2.66667 10.0854V4.75205H2C1.82319 4.75205 1.65362 4.82229 1.5286 4.94732C1.40357 5.07234 1.33333 5.24191 1.33333 5.41872V12.7521C1.33333 12.9289 1.40357 13.0984 1.5286 13.2235C1.65362 13.3485 1.82319 13.4187 2 13.4187H7.33333C7.51014 13.4187 7.67971 13.3485 7.80474 13.2235C7.92976 13.0984 8 12.9289 8 12.7521V12.0854Z"
      fill="#16ACD9"
      fill-opacity="0.5"
    />
  </svg>

</template>

<script>
export default {
  name: 'CopyTextIcon',
  emits: ['click'],
}
</script>
