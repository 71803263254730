<!-- eslint-disable vue/this-in-template -->
<template>
  <div class="h-100">
    <!-- Header -->
    <k-header-card class="d-flex justify-content-between">
      <div
        class="w-50 d-flex justify-content-center align-items-start flex-column"
      >
        <span
          class="text-primary d-flex align-items-center cursor-pointer"
          @click="routeToPreviousPage"
        >
          <feather-icon
            class="me-1"
            icon="ChevronLeftIcon"
          /> Back {{ previousRouteText }}
        </span>
        <div
          v-if="userData"
          class="d-flex flex-column col-sm-12 col-lg-6 text-start"
        >
          <div>
            <span class="kp-text-color">{{ this.entityType === 'brand' ? 'Brand' : 'Retailer' }} ID:</span>
            <span class="text-primary ms-1">{{ userData._id || userData.id }}</span>
            <span
              class="float-end cursor-pointer"
              @click="copyToClipboard(userData._id || userData.id)"
            ><copy-text-icon /></span>
          </div>
          <div
            v-if="userData.profileLink"
            class="d-flex flex-row align-items-center"
          >
            <b-link
              class="
                d-inline-block
                text-truncate
                text-decoration-none
              "
              :href="userData.profileLink"
              target="_blank"
            >
              <span class="kp-text-color">Magic link:</span>
              <span class="ms-1">{{ userData.profileLink }}</span>
            </b-link>
            <span
              class="float-end cursor-pointer"
              @click="copyToClipboard(userData.profileLink)"
            ><copy-text-icon /></span>
          </div>
        </div>
      </div>
      <div class="w-25 d-flex justify-content-end align-items-center">
        <k-button
          variant="outline-info"
          class="px-5"
          @click="onClickEditOrCancel"
        >
          {{ isEditEnabled ? 'CANCEL' : 'EDIT' }}
        </k-button>
        <k-button
          v-if="isEditEnabled"
          class="ms-3 text-nowrap"
          variant="info"
          :disabled="isSaving || !isFieldsEdited || !isInvalidInput"
          @click="onClickSave"
        >
          <loader-icon v-if="isSaving" />
          <save-icon
            v-else
            class="me-2"
          />
          SAVE CHANGES
        </k-button>
      </div>
    </k-header-card>
    <!-- Body of the page -->
    <div class="kp-body-container">
      <validation-observer
        ref="user_details_form"
      >
        <div
          v-if="isLoading"
          class="spinner-body"
        >
          <b-spinner />
        </div>
        <div
          v-else
          class="row card-container"
        >
          <div class="col-lg-6 col-xs-12 pe-lg-2 d-flex flex-column">
            <k-card class="mb-3">
              <general-info
                :user-data="userData"
                :is-edit-enabled="isEditEnabled"
              />
            </k-card>
            <k-card class="mb-3">
              <internal-info
                :user-data="userData"
                :is-edit-enabled="isEditEnabled"
              />
            </k-card>
            <k-card class="mb-3">
              <segmentation-info
                :user-data="userData"
                :is-edit-enabled="false"
              />
            </k-card>
          </div>
          <div class="col-lg-6 col-xs-12 ps-lg-2 d-flex flex-column">
            <k-card class="mb-3">
              <commercial-info
                :user-data="userData"
                :is-edit-enabled="isEditEnabled"
                @updateContractFile="updateContractFile"
              />
            </k-card>
            <k-card class="mb-3">
              <address-info :addresses="addresses" />
            </k-card>
            <k-card>
              <team-mates-info
                :user-data="userData"
              />
            </k-card>
          </div>
        </div>
      </validation-observer>
      <kp-modal
        id="update_user_details"
        header-text="Update User Details"
        close-btn-text="No"
        ok-btn-text="Yes"
        variant="info"
        :loading="isSaving"
        @on-click-ok="onClickSave(false, true)"
        @on-click-cancel="closeModal"
      >
        <p>{{ errMessage }}</p>
      </kp-modal>
    </div>
  </div>
</template>

<script>
import KHeaderCard from '@/@core/components/KHeaderCard.vue'
import FeatherIcon from '@/components/feather-icon/FeatherIcon.vue'
import { KButton, KCard } from '@kingpin-global/kingpin-ui'
import CopyTextIcon from '@/assets/images/svg/CopyTextIcon.vue'
import {
  FETCH_BRAND, FETCH_RETAILER, UPDATE_BRAND, UPDATE_RETAILER,
} from '@/store/modules/user-module'
import {
  apiToastError, apiToastErrorV2, apiToastSuccessV2, parseErrorObject,
} from '@/@core/utils/toast'
import { BSpinner, BLink, VBModal } from 'bootstrap-vue'
import { copyToClipboard } from '@/common-utils'
import { FETCH_COMMON, UPLOAD_DOCUMENTS } from '@/store/modules/common.module'
import { LoaderIcon } from 'vue-feather-icons'
import SaveIcon from '@/assets/images/svg/SaveIcon.vue'
import { ValidationObserver } from 'vee-validate'
import {
  min,
  email,
  numeric,
  phNo,
} from '@/@core/utils/validations/validations'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import { ROUTES } from '@/constants'
import KpModal from '@/@core/components/KpModal.vue'
import GeneralInfo from './GeneralInfo.vue'
import InternalInfo from './InternalInfo.vue'
import SegmentationInfo from './SegmentationInfo.vue'
import CommercialInfo from './CommercialInfo.vue'
import AddressInfo from './AddressInfo.vue'
import TeamMatesInfo from './TeamMatesInfo.vue'

const { ROLES, USER_STATUS } = constants

export default {
  name: 'UserDetails',
  components: {
    BSpinner,
    BLink,
    KHeaderCard,
    FeatherIcon,
    KButton,
    KCard,
    GeneralInfo,
    InternalInfo,
    SegmentationInfo,
    CopyTextIcon,
    CommercialInfo,
    AddressInfo,
    SaveIcon,
    LoaderIcon,
    ValidationObserver,
    KpModal,
    TeamMatesInfo,
  },
  directives: {
    'v-b-modal': VBModal,
  },
  data() {
    return {
      entityType: this.$route.query.entityType,
      userId: this.$route.params.id,
      userData: null,
      rawUserData: null,
      isLoading: false,
      isEditEnabled: false,
      isSaving: false,
      payload: {},
      isFieldsEdited: false,
      copyToClipboard,
      // for Validation
      min,
      email,
      numeric,
      phNo,
      prevRoute: null,
      previousRouteText: '',
      contractFile: null,
      errMessage: '',
      isInvalidInput: false,
      addresses: [],
    }
  },
  watch: {
    userData: {
      handler(val) {
        if (this.isEditEnabled) {
          this.setPayload(val)
        }
      },
      deep: true,
    },
  },
  created() {
    this.$store.dispatch(FETCH_COMMON)
    this.loadUser()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
      vm.getPreviousRoute(from)
    })
  },
  methods: {
    getUserWithDefaultData(res) {
      return {
        ...res,
        logisticsTerms: res.logisticsTerms || '',
        paymentTerms: res.paymentTerms || '',
        isLive: !!res.isLive,
        currency: res.currency,
      }
    },
    async loadUser() {
      this.isLoading = true
      await this.$store
        .dispatch(this.entityType === 'brand' ? FETCH_BRAND : FETCH_RETAILER, this.userId)
        .then(res => {
          const resData = res?.data?.data
          this.addresses = resData?.addresses || []
          const data = this.getUserWithDefaultData(resData)
          this.rawUserData = JSON.parse(JSON.stringify(data))
          this.userData = data
          this.isLoading = false
        })
        .catch(err => {
          apiToastError(err)
          this.isLoading = false
        })
    },
    async setPayload(updatedVal) {
      this.payload = {}
      this.isInvalidInput = await this.$refs.user_details_form.validate()
      if (updatedVal.commercialTerms) {
        updatedVal.commercialTerms = parseFloat(updatedVal.commercialTerms)
      }
      if (updatedVal.initialCreditLimit) {
        updatedVal.initialCreditLimit = parseFloat(updatedVal.initialCreditLimit)
      }
      for (const key in updatedVal) {
        // Checking array inputs is updated
        if (Array.isArray(updatedVal[key])) {
          // eslint-disable-next-line
            if(updatedVal[key].join() != this.rawUserData[key].join()) {
            this.payload[key] = updatedVal[key]
          }
        } else if (key === 'countryCode' && !updatedVal.phone) {
          // eslint-disable-next-line
          continue
        } else if (Boolean(updatedVal[key]) !== Boolean(this.rawUserData[key])) {
          this.payload[key] = updatedVal[key]
          // eslint-disable-next-line
        } else if (updatedVal[key] && updatedVal[key] != this.rawUserData[key]) {
          this.payload[key] = updatedVal[key]
        }
      }
      this.isFieldsEdited = !!(Object.keys(this.payload).length || this.contractFile)
    },
    async onClickSave(event, shouldProceed = false) {
      if (this.payload?.status === USER_STATUS.APPROVED) {
        if (!this.rawUserData?.access?.length && !this.payload?.access?.length) {
          apiToastErrorV2('Access field is mandatory')
          return
        }
      }
      if (this.contractFile && this.contractFile instanceof File) {
        const formData = new FormData()
        formData.append('file', this.contractFile)
        const fileLink = await this.$store.dispatch(UPLOAD_DOCUMENTS, { fileType: 'contract', payload: formData })
        this.payload.contractFile = fileLink
      }
      if (shouldProceed) {
        this.payload.shouldProceed = true
      }
      this.$refs.user_details_form.validate().then(success => {
        if (success) {
          this.isSaving = true
          this.$store.dispatch(this.entityType === 'brand' ? UPDATE_BRAND : UPDATE_RETAILER, { id: this.userId, payload: this.payload })
            .then(({ data }) => {
              apiToastSuccessV2(data.message)
              this.isEditEnabled = false
              this.isSaving = false
              this.isFieldsEdited = false
              this.loadUser()
              if (shouldProceed) {
                this.closeModal()
                delete this.payload.shouldProceed
              }
            })
            .catch(err => {
              if (err.response.status === 441) {
                this.$bvModal.show('update_user_details')
                this.errMessage = parseErrorObject(err)
              }
              else {
                apiToastErrorV2(err)
              }
              this.isSaving = false
            })
        }
      })
    },
    onClickEditOrCancel() {
      if (this.isEditEnabled) {
        this.userData = JSON.parse(JSON.stringify(this.rawUserData))
      }
      this.isEditEnabled = !this.isEditEnabled
      this.payload = {}
      this.isFieldsEdited = false
    },
    closeModal() {
      this.$bvModal.hide('update_user_details')
      this.errMessage = ''
    },
    getPreviousRoute() {
      if (this.prevRoute?.name === ROUTES.COLLECTIONS.name) {
        this.previousRouteText = 'To Collections'
      }
      else if ([ROUTES.NOTES.CHILDREN.CREDIT.name, ROUTES.NOTES.CHILDREN.DEBIT.name].includes(this.prevRoute?.name)) {
        this.previousRouteText = 'To Credit/Debit Notes'
      }
      else {
        this.previousRouteText = `To ${this.entityType === 'brand' ? 'Brands' : 'Retailers'}`
      }
    },
    routeToPreviousPage() {
      if (this.prevRoute?.name) {
        this.$router.push({ name: this.prevRoute?.name })
      }
      else if (this.userData?.role === ROLES.BRAND) {
        this.$router.push({ name: ROUTES.BRANDS.name })
      }
      else if (this.userData?.role === ROLES.RETAILER) {
        this.$router.push({ name: ROUTES.RETAILERS.name })
      }
      else {
        this.$router.go(-1)
      }
    },
    updateContractFile(contractFile) {
      this.contractFile = contractFile
      this.isFieldsEdited = !!(Object.keys(this.payload).length || this.contractFile)
    },
  },
}
</script>

<style lang="scss">
@import "@/@core/scss/vue/pages/kp-details-tab.scss";
@import "@/@core/scss/vue/components/address-form.scss";
</style>
