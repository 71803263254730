<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.626 4.126L12.374 0.874C12.224 0.724 12.04 0.614 11.84 0.554V0.5H0.64C0.286 0.5 0 0.786 0 1.14V15.86C0 16.214 0.286 16.5 0.64 16.5H15.36C15.714 16.5 16 16.214 16 15.86V5.03C16 4.69 15.866 4.366 15.626 4.126ZM5.44 1.94H10.56V4.02H5.44V1.94ZM14.56 15.06H1.44V1.94H4.16V4.66C4.16 5.014 4.446 5.3 4.8 5.3H11.2C11.554 5.3 11.84 5.014 11.84 4.66V2.376L14.56 5.096V15.06ZM8 7.1C6.41 7.1 5.12 8.39 5.12 9.98C5.12 11.57 6.41 12.86 8 12.86C9.59 12.86 10.88 11.57 10.88 9.98C10.88 8.39 9.59 7.1 8 7.1ZM8 11.58C7.116 11.58 6.4 10.864 6.4 9.98C6.4 9.096 7.116 8.38 8 8.38C8.884 8.38 9.6 9.096 9.6 9.98C9.6 10.864 8.884 11.58 8 11.58Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'SaveIcon',
}
</script>
