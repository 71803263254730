<template>
  <div>
    <div
      v-b-toggle.collapse_address_info
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span>Addresses</span>
        <span><edit-summary-collapse-icon
          :class="isVisible ? 'top-arrow' : 'down-arrow'"
        /></span>
      </p>
    </div>
    <b-collapse
      id="collapse_address_info"
      v-model="isVisible"
      class="card-body-container"
    >
      <div
        v-if="isLoading"
        class="spinner-body"
      >
        <b-spinner />
      </div>
      <div
        v-else
        class="w-100 d-flex flex-column p-2"
      >
        <div
          v-if="!billingAddress && !shippingAddressList.length"
          class="text-center fs-5 text-secondary"
        >
          No addresses found.
        </div>
        <div
          v-if="billingAddress"
          class="row"
        >
          <div class="col-12 p-0">
            <div class="content cursor-pointer mb-2 d-flex justify-content-between">
              <span
                class="d-flex align-items-center"
                @click="isVisibleUserBillingAddress = !isVisibleUserBillingAddress"
              >
                <caret-svg-icon
                  :class="isVisibleUserBillingAddress ? 'down' : 'right'"
                />
                <span class="address-label font-poppins">
                  Billing Address
                </span>
              </span>
              <span v-if="isVisibleUserBillingAddress && isEditEnabled">
                <k-button
                  class="mt-2"
                  size="sm"
                  variant="info"
                  :disabled="isSavingBillingAddress"
                >
                  {{ isSavingBillingAddress ? 'Saving' : 'Save' }}
                </k-button>
              </span>
            </div>
            <b-collapse :visible="isVisibleUserBillingAddress">
              <div>
                <address-form
                  :disabled-all-fields="!isEditEnabled"
                  :countries="countries"
                  :address-type="ADDRESSES_TYPE.BILLING"
                  :address-data="billingAddress"
                  :is-user-detail-page="true"
                />
              </div>
            </b-collapse>
          </div>
        </div>
        <div class="row">
          <div
            v-for="(address, index) of shippingAddressList"
            :key="index"
            class="col-12 p-0 mt-2"
          >
            <div class="content cursor-pointer mb-2 d-flex justify-content-between">
              <span
                class="d-flex align-items-center"
                @click="address.isVisible = !address.isVisible"
              >
                <caret-svg-icon
                  :class="address.isVisible ? 'down' : 'right'"
                />
                <span class="address-label font-poppins">
                  Shipping Address {{ shippingAddressList.length > 1 && index + 1 || '' }}
                </span>
              </span>
              <span v-if="address.isVisible && isEditEnabled && address.isPrimary">
                <k-button
                  class="mt-2"
                  size="sm"
                  variant="info"
                  :disabled="address.isSaving"
                >
                  {{ address.isSaving ? 'Saving' : 'Save' }}
                </k-button>
              </span>
            </div>
            <b-collapse :visible="address.isVisible">
              <div>
                <address-form
                  :disabled-all-fields="!isEditEnabled || !address.isPrimary"
                  :countries="countries"
                  :address-type="ADDRESSES_TYPE.SHIPPING"
                  :address-data="address"
                  :is-user-detail-page="true"
                />
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  VBToggle,
  BCollapse,
  BSpinner,
} from 'bootstrap-vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import AddressForm from '@/components/address-form/AddressForm.vue'
import { mapState } from 'vuex'
import { ADDRESSES_TYPE } from '@/constants'
import CaretSvgIcon from '@/assets/images/svg/CaretSvgIcon.vue'
import { KButton } from '@kingpin-global/kingpin-ui'

export default {
  name: 'AddressInfo',
  components: {
    BCollapse,
    EditSummaryCollapseIcon,
    AddressForm,
    CaretSvgIcon,
    BSpinner,
    KButton,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  props: {
    addresses: {
      type: Array,
      default: () => [],
    },
    isEditEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userId: this.$route.params.id,
      isVisible: true,
      ADDRESSES_TYPE,
      isVisibleUserBillingAddress: true,
      billingAddress: null,
      shippingAddressList: [],
      isLoading: false,
      isSavingBillingAddress: false,
    }
  },
  computed: {
    ...mapState({
      countries: state => state.common.countries,
    }),
  },
  created() {
    this.loadAddress()
  },
  methods: {
    loadAddress() {
      this.billingAddress = this.addresses.find(address => address.addressType === ADDRESSES_TYPE.BILLING)
      this.shippingAddressList = this.addresses.filter(address => address.addressType === ADDRESSES_TYPE.SHIPPING).map(_ => ({
        ..._,
        isVisible: true,
        isSaving: false,
      }))
    },
  },
}
</script>
