<template>
  <div>
    <div class="px-3">
      <!-- Filter section -->
      <div class="filter-sorting-container">
        <k-search-bar-section
          v-b-tooltip.hover.bottom="searchPlaceholder"
          class="w-50"
          :placeholder="searchPlaceholder"
          hide-sort-option
          hide-filter-option
          hide-column-edit-option
          @on-search="updateSearch"
        />
        <k-sorting
          class="ms-auto"
          hide-column-edit-option
          :sort-items="tableFields"
          @update-sort="updateSort"
          @update-sort-direction="updateSortDirection"
        />
      </div>
      <!-- Table section -->
      <div
        v-if="isLoading"
        class="spinner-body"
      >
        <b-spinner />
      </div>
      <div
        v-else
        class=" poisiton-relative table-responsive"
      >
        <p-table
          :fields="tableFields"
          :items="items"
          :filter="searchText"
          :sort-by="sortBy"
          :per-page="perPage"
          :sort-desc="isDesc"
          :current-page="currentPage"
          :filter-included-fields="filterIncludedFields"
          @filtered="getFilteredItems"
        >
          <template #emptyfiltered>
            <p
              class="
                h2
                d-flex
                table-empty-message
                justify-content-center
                align-items-center
                gap-2
              "
            >
              <b-icon
                icon="exclamation-circle"
                scale="1"
              />
              <span> No Items Found </span>
            </p>
          </template>
          <template #empty>
            <p
              class="
                h2
                d-flex
                table-empty-message
                justify-content-center
                align-items-center
                gap-2
              "
            >
              <b-icon
                icon="exclamation-circle"
                scale="1"
              />
              <span> No Filtered Items </span>
            </p>
          </template>
          <!-- Column: First Name -->
          <template #cell(firstName)="data">
            <b-media vertical-align="center">
              <span class="d-block text-nowrap">
                {{ data.item.firstName }}
              </span>
            </b-media>
          </template>
          <!-- Column: Last Name -->
          <template #cell(lastName)="data">
            <b-media vertical-align="center">
              <span class="d-block text-nowrap">
                {{ data.item.lastName }}
              </span>
            </b-media>
          </template>
          <!-- Column: Email -->
          <template #cell(email)="data">
            <b-media vertical-align="center">
              <span class="d-block text-nowrap">
                {{ data.item.email }}
              </span>
            </b-media>
          </template>
          <!-- Role -->
          <template #cell(role)="data">
            <b-media vertical-align="center">
              <span class="d-block text-nowrap text-capitalize">
                {{ data.item.role }}
              </span>
            </b-media>
          </template>
          <!-- Actions -->
          <template #cell(magicLink)="data">
            <div
              v-if="data.item && data.item.magicLink"
              class="d-flex text-primary w-100"
            >
              <b-link
                :href="data.item.magicLink"
                target="_blank"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="EyeIcon"
                  size="16"
                />
              </b-link>
              <div
                class="cursor-pointer ms-3"
                @click="onClickCopy(data.item.magicLink)"
              >
                <copy-text-icon />
              </div>
            </div>
          </template>
        </p-table>
      </div>
      <k-table-pagination
        :total-items="itemsCount"
        @emit-current-page="updateCurrentPage"
        @emit-per-page="updatePerPage"
      />
    </div>
  </div>
</template>

<script>
import { KSearchAndSortMixin } from '@/mixins/k-searbar-section.mixin'
import { getTeamMateFields } from '@/table-fields-constants'
import {
  KSearchBarSection,
  KSorting,
  KTablePagination,
  PTable,
} from '@kingpin-global/kingpin-ui'
import {
  BIcon, BLink, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import FeatherIcon from '@/components/feather-icon/FeatherIcon.vue'
import { USER_ROLES_LABEL } from '@/constants'
import { copyToClipboard } from '@/common-utils'
import CopyTextIcon from '@/assets/images/svg/CopyTextIcon.vue'

export default {
  name: 'TeamMatesList',
  components: {
    PTable,
    BIcon,
    KTablePagination,
    BSpinner,
    KSearchBarSection,
    KSorting,
    FeatherIcon,
    CopyTextIcon,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [KSearchAndSortMixin],
  props: {
    teamMates: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [],
      tableFields: [],
      filteredItems: [],
      sortBy: 'createdAt',
      searchText: '',
      loading: true,
      itemsCount: 0,
      perPage: 10,
      currentPage: 1,
      currentPageCount: 0,
      isDesc: false,
      isLoading: false,
      items: [],
      USER_ROLES_LABEL,
      searchPlaceholder: 'Search by name, email...',
    }
  },
  computed: {
    filterIncludedFields() {
      return this.tableFields.map(fields => fields.key)
    },
  },
  mounted() {
    this.initPageContent()
  },
  methods: {
    async initPageContent() {
      this.isLoading = true
      this.setItems()
      this.itemsCount = this.items.length
      this.filteredItems = this.items
      this.getCurrentPageCount()
      this.isLoading = false
      this.getTableFields()
      this.setTableData()
    },
    setItems() {
      this.items = this.teamMates.map(scope => ({
        ...scope,
        firstName: scope.user?.firstName,
        lastName: scope.user?.lastName,
        email: scope.user?.email,
        magicLink: scope.user?.profileLink,
        role: USER_ROLES_LABEL[scope.role] || scope.role,
      }))
    },
    getTableFields() {
      this.tableFields = getTeamMateFields()
    },
    setTableData() {
      this.filteredItems = this.items
      this.itemsCount = this.items.length
      this.getCurrentPageCount()
    },
    updateSort(value) {
      this.sortBy = value
    },
    updateSearch(value) {
      this.searchText = value
    },
    updateCurrentPage(value) {
      this.currentPage = value
      this.getCurrentPageCount()
    },
    updatePerPage(value) {
      this.perPage = value
      this.getCurrentPageCount()
    },
    updateSortDirection(value) {
      this.isDesc = value
    },
    getFilteredItems(items) {
      this.itemsCount = items.length
      this.filteredItems = items
      this.getCurrentPageCount()
    },
    getCurrentPageCount() {
      const totalCount = this.filteredItems.length
      const pageCount = totalCount / this.perPage
      if (this.currentPage <= pageCount) {
        this.currentPageCount = this.perPage
      }
      else {
        this.currentPageCount = Math.ceil(totalCount % this.perPage)
      }
    },
    onClickCopy(text) {
      copyToClipboard(text, 'Profile link was saved to your clipboard successfully!')
    },
  },
}
</script>
