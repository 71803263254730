<template>
  <div>
    <div
      v-b-toggle.collapse_team_mates_info
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span>Teammates</span>
        <span><edit-summary-collapse-icon
          :class="isVisible ? 'top-arrow' : 'down-arrow'"
        /></span>
      </p>
    </div>
    <b-collapse
      id="collapse_team_mates_info"
      v-model="isVisible"
      class="card-body-container p-0"
    >
      <div class="w-100 d-flex flex-column">
        <team-mates-list
          :team-mates="userData.userAssociations"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  VBToggle,
  BCollapse,
} from 'bootstrap-vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import TeamMatesList from './TeamMatesList.vue'

export default {
  name: 'TeamMatesInfo',
  components: {
    BCollapse,
    EditSummaryCollapseIcon,
    TeamMatesList,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  props: {
    userData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isVisible: true,
    }
  },
}
</script>
