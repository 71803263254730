<template>
  <div>
    <div
      v-b-toggle.collapse_segmentation_info
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span>Segmentation</span>
        <span><edit-summary-collapse-icon
          :class="isVisible ? 'top-arrow' : 'down-arrow'"
        /></span>
      </p>
    </div>
    <b-collapse
      id="collapse_segmentation_info"
      v-model="isVisible"
      class="card-body-container"
    >
      <div class="w-100 d-flex flex-column p-2">
        <div class="row">
          <div
            v-if="departments.length"
            class="col-12 p-0"
          >
            <k-form-group
              class="m-1"
              label-content="Departmens"
              label-class="font-work-sans"
            >
              <v-select
                v-model="userData.departments"
                class="v-select-custom font-inter"
                multiple
                :options="departments"
                placeholder="Select departmens"
                :close-on-select="false"
                :deselect-from-dropdown="true"
                :autoscroll="true"
                :clear-search-on-select="false"
                :searchable="true"
                :disabled="!isEditEnabled"
              />
            </k-form-group>
          </div>
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
              label-content="Avg wholesale price"
              label-class="font-work-sans"
            >
              <b-dropdown
                class="w-100 k-search-dropdown dropdown-custom-class"
                :class="{ 'has-value': userData.avgWholesalePrice }"
                variant="none"
                toggle-class="drop-caret-icon"
                :text="userData.avgWholesalePrice || 'Select a Avg wholesale price'"
                :disabled="!isEditEnabled"
              >
                <b-dropdown-item
                  v-for="priceText of segmentation.wholesalePrices"
                  :key="priceText"
                  :active="userData.avgWholesalePrice === priceText"
                  @click="userData.avgWholesalePrice = priceText"
                >
                  <span>
                    {{ priceText }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
          </div>
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
              label-content="Avg retail price"
              label-class="font-work-sans"
            >
              <b-dropdown
                class="w-100 k-search-dropdown dropdown-custom-class"
                :class="{ 'has-value': userData.avgRetailPrice }"
                variant="none"
                toggle-class="drop-caret-icon"
                :text="userData.avgRetailPrice || 'Select a Avg retail price'"
                :disabled="!isEditEnabled"
              >
                <b-dropdown-item
                  v-for="priceText of segmentation.retailPrices"
                  :key="priceText"
                  :active="userData.avgRetailPrice === priceText"
                  @click="userData.avgRetailPrice = priceText"
                >
                  <span>
                    {{ priceText }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  VBToggle,
  BCollapse,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import { KFormGroup } from '@kingpin-global/kingpin-ui'
import { mapState } from 'vuex'
import { segmentation } from '@/constants'

export default {
  name: 'SegmentationInfo',
  components: {
    BCollapse,
    BDropdown,
    BDropdownItem,
    EditSummaryCollapseIcon,
    KFormGroup,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  props: {
    userData: {
      type: Object,
      default() {
        return {}
      },
    },
    isEditEnabled: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      isVisible: true,
      segmentation,
    }
  },
  computed: {
    ...mapState({
      departments: state => state.common.departments,
    }),
  },
  created() {
    this.userData.avgWholesalePrice = this.userData.avgWholesalePrice || segmentation.wholesalePrices[0]
    this.userData.avgRetailPrice = this.userData.avgRetailPrice || segmentation.retailPrices[0]
  },
}
</script>
