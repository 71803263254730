<template>
  <div>
    <div
      v-b-toggle.collapse_commercial_info
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span>Commercial information</span>
        <span><edit-summary-collapse-icon
          :class="isVisible ? 'top-arrow' : 'down-arrow'"
        /></span>
      </p>
    </div>
    <b-collapse
      id="collapse_commercial_info"
      v-model="isVisible"
      class="card-body-container"
    >
      <div class="w-100 d-flex flex-column p-2">
        <div class="row">
          <div class="col-6 p-0">
            <validation-provider
              #default="{ errors }"
              name="Company Name"
              rules="min:3"
            >
              <k-form-group
                class="m-1"
                label-content="Company Name"
                label-class="font-work-sans"
                :form-invalid-content="errors[0]"
                :state="errors.length > 0 ? false : null"
              >
                <k-form-input
                  :value="userData.companyName"
                  type="text"
                  placeholder="Company Name"
                  :state="errors.length > 0 ? false : null"
                  disabled
                />
              </k-form-group>
            </validation-provider>
          </div>
          <div class="col-6 p-0">
            <validation-provider
              #default="{ errors }"
              name="Commercial Terms"
              rules="numeric|maxValue:25"
            >
              <k-form-group
                class="m-1"
                label-content="Commercial Terms"
                label-class="font-work-sans"
                :form-invalid-content="errors[0]"
                :state="errors.length > 0 ? false : null"
              >
                <k-form-input
                  v-model="userData.commercialTerms"
                  type="number"
                  placeholder="Commercial Terms"
                  :state="errors.length > 0 ? false : null"
                  disabled
                />
              </k-form-group>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
              label-content="Payment Terms"
              label-class="font-work-sans"
            >
              <b-dropdown
                class="w-100 k-search-dropdown dropdown-custom-class"
                :class="{ 'has-value': userData.paymentTerms }"
                variant="none"
                toggle-class="drop-caret-icon"
                :text="userData.paymentTerms || 'Select a Term'"
                disabled
              >
                <b-dropdown-item
                  v-for="term of paymentTerms"
                  :key="term"
                  :active="userData.paymentTerms === term"
                  @click="userData.paymentTerms = term"
                >
                  <span class="text-capitalize">
                    {{ term }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
          </div>
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
              label-content="Logistics Terms"
              label-class="font-work-sans"
            >
              <b-dropdown
                class="w-100 k-search-dropdown dropdown-custom-class"
                :class="{ 'has-value': userData.logisticsTerms }"
                variant="none"
                toggle-class="drop-caret-icon"
                :text="userData.logisticsTerms || 'Select a Term'"
                disabled
              >
                <b-dropdown-item
                  v-for="term in LOGISTICS_TERMS"
                  :key="term"
                  :active="userData.logisticsTerms === term"
                  @click="userData.logisticsTerms = term"
                >
                  <span>
                    {{ term }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
              label-content="Trade License number"
              label-class="font-work-sans"
            >
              <k-form-input
                :value="userData.tradeLicenseNumber"
                type="text"
                placeholder="Trade License number"
                disabled
              />
            </k-form-group>
          </div>
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
              label-content="Vat Certificate number"
              label-class="font-work-sans"
            >
              <k-form-input
                :value="userData.vatCertificateNumber"
                type="text"
                placeholder="Vat Certificate number"
                disabled
              />
            </k-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
              label-content="Initial credit limit"
              label-class="font-work-sans"
            >
              <k-form-input
                v-model="userData.initialCreditLimit"
                type="number"
                placeholder="Initial credit limit"
                :disabled="!isEditEnabled"
              />
            </k-form-group>
          </div>
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
              label-content="Available credit limit"
              label-class="font-work-sans"
            >
              <k-form-input
                :value="Math.max(userData.availableCreditLimit, 0) || 0"
                type="text"
                placeholder="Available credit limit"
                disabled
              />
            </k-form-group>
          </div>
        </div>
        <!-- Documents -->
        <documents-info
          class="mt-3"
          :user-data="userData"
          :is-edit-enabled="isEditEnabled"
          @updateContractFile="updateContractFile"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  VBToggle,
  BCollapse,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import { KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import { extend, ValidationProvider } from 'vee-validate'
import {
  maxValue,
} from '@/@core/utils/validations/validations'
import DocumentsInfo from './DocumentsInfo.vue'

const { LOGISTICS_TERMS, PAYMENT_TERMS } = constants

extend('maxValue', {
  ...maxValue,
  message: 'Maximum value should be 25.',
})

const paymentTerms = Object.values(PAYMENT_TERMS).map(_ => _.TEXT)

export default {
  name: 'CommercialInfo',
  components: {
    BCollapse,
    EditSummaryCollapseIcon,
    KFormGroup,
    KFormInput,
    BDropdown,
    BDropdownItem,
    DocumentsInfo,
    ValidationProvider,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  props: {
    userData: {
      type: Object,
      default() {
        return {}
      },
    },
    isEditEnabled: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      isVisible: true,
      LOGISTICS_TERMS,
      paymentTerms,
    }
  },
  methods: {
    updateContractFile(contractFile) {
      this.$emit('updateContractFile', contractFile)
    },
  },
  emits: ['updateContractFile'],
}
</script>
