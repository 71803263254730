<template>
  <div>
    <div
      v-b-toggle.collapse_internal_info
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span>Internal information</span>
        <span><edit-summary-collapse-icon
          :class="isVisible ? 'top-arrow' : 'down-arrow'"
        /></span>
      </p>
    </div>
    <b-collapse
      id="collapse_internal_info"
      v-model="isVisible"
      class="card-body-container"
    >
      <div class="w-100 d-flex flex-column p-2">
        <div class="row">
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
              label-content="Hubspot Contact"
              label-class="font-work-sans"
            >
              <k-form-input
                v-model="userData.hubspotContactId"
                type="text"
                placeholder="Hubspot Contact"
                :disabled="!isEditEnabled"
              />
              <copy-text-icon
                v-if="!isEditEnabled && userData.hubspotContactId"
                class="input-append-icon-position"
                @click="copyToClipboard(userData.hubspotContactId)"
              />
            </k-form-group>
          </div>
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
              label-content="Hubspot Deal"
              label-class="font-work-sans"
            >
              <k-form-input
                v-model="userData.hubspotDealId"
                type="text"
                placeholder="Hubspot Deal"
                :disabled="!isEditEnabled"
              />
              <copy-text-icon
                v-if="!isEditEnabled && userData.hubspotDealId"
                class="input-append-icon-position"
                @click="copyToClipboard(userData.hubspotDealId)"
              />
            </k-form-group>
          </div>
        </div>

        <div class="row d-flex align-items-end">
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
              label-content="Referral Code"
              label-class="font-work-sans"
            >
              <k-form-input
                :value="userData.referralCode"
                type="text"
                placeholder="Referral Code"
                disabled
              />
            </k-form-group>
          </div>
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
            >
              <k-button
                variant="outline-info"
                class="send-mail-btn"
                block
                :disabled="!isEditEnabled || isSendingWelcomeMail"
                @click="onClickSendWelcomeEmail"
              >
                <loader-icon v-if="isSendingWelcomeMail" />
                <span v-else>Send Welcome Email</span>
              </k-button>
            </k-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
              label-content="Access"
              label-class="font-work-sans"
            >
              <v-select
                v-model="userData.access"
                class="v-select-custom mt-0 text-capitalize font-inter"
                multiple
                :options="Object.values(BRAND_ACCESS)"
                placeholder="Select access"
                :close-on-select="false"
                :deselect-from-dropdown="true"
                :autoscroll="true"
                :clear-search-on-select="true"
                :searchable="true"
                :disabled="!isEditEnabled"
              />
            </k-form-group>
          </div>
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
              label-content="Currency"
              label-class="font-work-sans"
            >
              <v-select
                v-model="userData.currency"
                class="v-select-custom mt-0 text-capitalize font-inter"
                :options="Object.values(CURRENCY_CONVERSIONS).map((currency) => currency.LABEL)"
                placeholder="Select currency"
                :disabled="!isEditEnabled"
              />
            </k-form-group>
          </div>
        </div>
        <!-- Checkboxes -->
        <div class="row">
          <div class="col-12 p-3 d-flex flex-row align-items-center">
            <span>
              <label
                class="custom-checkbox-container"
              >
                <input
                  v-model="userData.isLive"
                  type="checkbox"
                  :disabled="!isEditEnabled"
                >
                <span class="checkmark" />
              </label>
            </span>
            <span
              class="ps-4 cursor-pointer"
              @click="() => {if(isEditEnabled) userData.isLive=!userData.isLive}"
            >Is live</span>
          </div>
          <div class="col-12 p-3 d-flex flex-row align-items-center">
            <span>
              <label
                class="custom-checkbox-container"
              >
                <input
                  v-model="userData.isDemo"
                  type="checkbox"
                  :disabled="!isEditEnabled"
                >
                <span class="checkmark" />
                <span class="demo-padding">Is demo</span>
              </label>
            </span>

          </div>
          <div class="col-12 p-3 d-flex flex-row align-items-center">
            <span>
              <label
                class="custom-checkbox-container"
              >
                <input
                  v-model="userData.isPremium"
                  type="checkbox"
                  :disabled="!isEditEnabled"
                >
                <span class="checkmark" />
              </label>
            </span>
            <span
              class="ps-4 cursor-pointer"
              @click="() => {if(isEditEnabled) userData.isPremium=!userData.isPremium}"
            >Is premium</span>
          </div>
          <div class="col-12 p-3 d-flex flex-row align-items-center">
            <span>
              <label
                class="custom-checkbox-container"
              >
                <input
                  v-model="userData.isWspHidden"
                  type="checkbox"
                  :disabled="!isEditEnabled"
                >
                <span class="checkmark" />
              </label>
            </span>
            <span
              class="ps-4 cursor-pointer"
              @click="() => {if(isEditEnabled) userData.isWspHidden=!userData.isWspHidden}"
            >Is wsp hidden</span>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  VBToggle,
  BCollapse,
} from 'bootstrap-vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import { KFormGroup, KFormInput, KButton } from '@kingpin-global/kingpin-ui'
import CopyTextIcon from '@/assets/images/svg/CopyTextIcon.vue'
import { copyToClipboard } from '@/common-utils'
import { UPDATE_USER } from '@/store/modules/user-module'
import { apiToastErrorV2, apiToastSuccessV2 } from '@/@core/utils/toast'
import { LoaderIcon } from 'vue-feather-icons'
import { constants } from '@kingpin-global/kingpin-utils-frontend'

const { BRAND_ACCESS, CURRENCY_CONVERSIONS } = constants

export default {
  name: 'InternalInfo',
  components: {
    BCollapse,
    EditSummaryCollapseIcon,
    KFormGroup,
    KFormInput,
    KButton,
    CopyTextIcon,
    LoaderIcon,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  props: {
    userData: {
      type: Object,
      default() {
        return {}
      },
    },
    isEditEnabled: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      isVisible: true,
      isSendingWelcomeMail: false,
      copyToClipboard,
      BRAND_ACCESS,
      CURRENCY_CONVERSIONS,
    }
  },
  methods: {
    onClickSendWelcomeEmail() {
      this.isSendingWelcomeMail = true
      this.$store.dispatch(UPDATE_USER, { id: this.userData._id, payload: { sendWelcomeEmail: true } })
        .then(() => {
          apiToastSuccessV2('Welcome mail sent!')
          this.isSendingWelcomeMail = false
        })
        .catch(() => {
          apiToastErrorV2('Failed to send mail')
          this.isSendingWelcomeMail = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables-components.scss";

.send-mail-btn {
  min-height: $input-height;
}
.demo-padding {
  position: relative;
  top: -3px;
  padding-left: 1.5rem;
}
</style>
