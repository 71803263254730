<template>
  <div>
    <div
      v-b-toggle.collapse_general_info
      class="card-header-container"
    >
      <p
        class="
          w-100
          font-poppins
          card-header-text
          text-start
          d-flex
          justify-content-between
        "
      >
        <span>General information</span>
        <span><edit-summary-collapse-icon
          :class="isVisible ? 'top-arrow' : 'down-arrow'"
        /></span>
      </p>
    </div>
    <b-collapse
      id="collapse_general_info"
      v-model="isVisible"
      class="card-body-container"
    >
      <div class="w-100 d-flex flex-column p-2">
        <div class="row">
          <div class="col-6 p-0">
            <validation-provider
              #default="{ errors }"
              name="Entity Name"
              rules="min:2"
            >
              <k-form-group
                class="m-1"
                label-content="Entity Name"
                label-class="font-work-sans"
                :form-invalid-content="errors[0]"
                :state="errors.length > 0 ? false : null"
              >
                <k-form-input
                  v-model="userData.entityName"
                  type="text"
                  placeholder="Entity Name"
                  :state="errors.length > 0 ? false : null"
                  :disabled="!isEditEnabled"
                />
              </k-form-group>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
              label-content="Status"
              label-class="font-work-sans"
            >
              <b-dropdown
                class="w-100 k-search-dropdown dropdown-custom-class"
                :class="{ 'has-value': userData.status }"
                variant="none"
                toggle-class="drop-caret-icon"
                :text="userData.status || 'Select a Status'"
                :disabled="!isEditEnabled"
              >
                <b-dropdown-item
                  v-for="status in USER_STATUS"
                  :key="status"
                  :active="userData.status === status"
                  @click="userData.status = status"
                >
                  <span class="text-capitalize">
                    {{ status }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
          </div>
          <div class="col-6 p-0">
            <k-form-group
              class="m-1"
              label-content="Country"
              label-class="font-work-sans"
            >
              <b-dropdown
                class="w-100 k-search-dropdown dropdown-custom-class"
                :class="{ 'has-value': userData.country }"
                variant="none"
                toggle-class="drop-caret-icon"
                :text="userData.country || 'Select a Country'"
                disabled
              >
                <b-dropdown-item
                  v-for="country of countries"
                  :key="country"
                  :active="userData.country === country"
                  @click="userData.country = country"
                >
                  <span>
                    {{ country }}
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </k-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-6 p-0">
            <validation-provider
              #default="{ errors }"
              name="First Name"
              rules="min:3"
            >
              <k-form-group
                class="m-1"
                label-content="First Name"
                label-class="font-work-sans"
                :form-invalid-content="errors[0]"
                :state="errors.length > 0 ? false : null"
              >
                <k-form-input
                  v-model="userData.firstName"
                  type="text"
                  placeholder="First Name"
                  :state="errors.length > 0 ? false : null"
                  disabled
                />
              </k-form-group>
            </validation-provider>
          </div>
          <div class="col-6 p-0">
            <validation-provider
              #default="{ errors }"
              name="Last Name"
              rules="min:1"
            >
              <k-form-group
                class="m-1"
                label-content="Last Name"
                label-class="font-work-sans"
                :form-invalid-content="errors[0]"
                :state="errors.length > 0 ? false : null"
              >
                <k-form-input
                  v-model="userData.lastName"
                  type="text"
                  placeholder="Last Name"
                  :state="errors.length > 0 ? false : null"
                  disabled
                />
              </k-form-group>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-6 p-0">
            <validation-provider
              #default="{ errors }"
              name="Email Address"
              rules="email"
            >
              <k-form-group
                class="m-1"
                label-content="Email"
                label-class="font-work-sans"
                :form-invalid-content="errors[0]"
                :state="errors.length > 0 ? false : null"
              >
                <k-form-input
                  v-model="userData.email"
                  type="email"
                  placeholder="Email"
                  :state="errors.length > 0 ? false : null"
                  :disabled="!isEditEnabled"
                />
                <copy-text-icon
                  v-if="!isEditEnabled && userData.email"
                  class="input-append-icon-position"
                  @click="copyToClipboard(userData.email)"
                />
              </k-form-group>
            </validation-provider>
          </div>
          <div class="col-6 p-0">
            <validation-provider
              #default="{ errors }"
              name="Phone"
              rules="phNo|min:8"
            >
              <k-form-group
                class="m-1 phone-contact-input"
                label-content="Phone"
                label-class="font-work-sans"
                :form-invalid-content="errors[0]"
                :state="errors.length > 0 ? false : null"
              >
                <vue-tel-input
                  :value="userData.phone ? fullPhoneNumber : ''"
                  :input-options="inputOptions"
                  mode="international"
                  name="phone"
                  disabled
                  @input="onInputPhoneNumber"
                />
                <copy-text-icon
                  v-if="!isEditEnabled && userData.phone"
                  class="input-append-icon-position"
                  @click="copyToClipboard(userData.phone)"
                />
              </k-form-group>
            </validation-provider>
          </div>
        </div>

        <div class="row">
          <div class="col-12 p-0">
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="min:3"
            >
              <k-form-group
                class="m-1"
                label-content="Store Description"
                label-class="font-work-sans"
                :form-invalid-content="errors[0]"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea
                  v-model="userData.storeDescription"
                  placeholder="Store Description"
                  rows="3"
                  :state="errors.length > 0 ? false : null"
                  disabled
                />
              </k-form-group>
            </validation-provider>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  VBToggle,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BFormTextarea,
} from 'bootstrap-vue'
import EditSummaryCollapseIcon from '@/assets/images/svg/EditSummaryCollapseIcon.vue'
import { KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'
import { mapState } from 'vuex'
import { ROLES } from '@/constants'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import CopyTextIcon from '@/assets/images/svg/CopyTextIcon.vue'
import { copyToClipboard } from '@/common-utils'
import { ValidationProvider } from 'vee-validate'

const { USER_STATUS } = constants

const roles = [ROLES.RETAILER, ROLES.BRAND]

export default {
  name: 'GeneralInfo',
  components: {
    BCollapse,
    EditSummaryCollapseIcon,
    KFormGroup,
    KFormInput,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    CopyTextIcon,
    ValidationProvider,
  },
  directives: {
    'v-b-toggle': VBToggle,
  },
  props: {
    userData: {
      type: Object,
      default() {
        return {}
      },
    },
    isEditEnabled: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      isVisible: true,
      roles,
      USER_STATUS,
      copyToClipboard,
      inputOptions: {
        id: 'contact-number',
        placeholder: 'Enter phone number',
      },
      fullPhoneNumber: '',
    }
  },
  computed: {
    ...mapState({
      countries: state => state.common.countries,
    }),
  },
  created() {
    this.fullPhoneNumber = `${this.userData?.countryCode} ${this.userData?.phone}`
  },
  methods: {
    onInputPhoneNumber(number, phoneObject) {
      this.userData.phone = number && phoneObject.nationalNumber
      this.userData.countryCode = phoneObject.countryCallingCode ? `+${phoneObject.countryCallingCode}` : ''
      this.fullPhoneNumber = `${this.userData?.countryCode} ${this.userData?.phone}`
    },
  },
}
</script>
