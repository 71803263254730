<template>
  <div class="row document-info">
    <k-form-group
      class="p-1 w-100"
      label-content="Trade license"
      label-class="font-work-sans"
    >
      <k-form-input
        :value="userData.tradeLicense"
        type="text"
        placeholder="Trade license"
        disabled
        class="pe-5"
      />
      <kingpin-icon
        v-if="userData.tradeLicense"
        icon="DownloadCloudIcon"
        class="input-append-icon-position"
        @click="download(userData.tradeLicense)"
      />
    </k-form-group>
    <k-form-group
      class="p-1"
      label-content="VAT certificate"
      label-class="font-work-sans"
    >
      <k-form-input
        :value="userData.vatCertificate"
        type="text"
        placeholder="VAT certificate"
        disabled
        class="pe-5"
      />
      <kingpin-icon
        v-if="userData.vatCertificate"
        icon="DownloadCloudIcon"
        class="input-append-icon-position"
        @click="download(userData.vatCertificate)"
      />
    </k-form-group>
    <k-form-group
      class="p-1"
      label-content="Company profile"
      label-class="font-work-sans"
    >
      <k-form-input
        :value="userData.companyProfile"
        type="text"
        placeholder="Company profile"
        disabled
        class="pe-5"
      />
      <kingpin-icon
        v-if="userData.companyProfile"
        icon="DownloadCloudIcon"
        class="input-append-icon-position"
        @click="download(userData.companyProfile)"
      />
    </k-form-group>
    <k-form-group
      class="p-1"
      label-content="Other files"
      label-class="font-work-sans"
    >
      <k-form-input
        :value="userData.others"
        type="text"
        placeholder="Other files"
        disabled
        class="pe-5"
      />
      <kingpin-icon
        v-if="userData.others"
        icon="DownloadCloudIcon"
        class="input-append-icon-position"
        @click="download(userData.others)"
      />
    </k-form-group>
    <k-form-group
      class="p-1"
      label-content="Website link"
      label-class="font-work-sans"
    >
      <k-form-input
        :value="userData.websiteLink"
        type="text"
        placeholder="Website link"
        disabled
        class="pe-5"
      />
      <kingpin-icon
        v-if="userData.websiteLink"
        icon="CopyTextIcon"
        class="input-append-icon-position"
        @click="copyToClipboard(userData.websiteLink)"
      />
    </k-form-group>
    <k-form-group
      class="p-1"
      label-content="Contract File"
      label-class="font-work-sans"
    >
      <k-form-input
        :value="userData.contractFile"
        type="text"
        placeholder="Contract File"
        disabled
        class="pe-5"
      />
      <kingpin-icon
        v-if="userData.contractFile"
        icon="DownloadCloudIcon"
        class="input-append-icon-position"
        @click="download(userData.contractFile)"
      />
    </k-form-group>
    <div
      v-if="!contractFile"
      class="mt-1 p-1 upload-file-button"
    >
      <label
        for="upload_files"
        class="upload-files d-flex justify-content-center align-items-center"
        :class="!isEditEnabled ? 'disabled': ''"
      >
        <kingpin-icon
          icon="PlusIcon"
          size="14"
        />
        <span class="button-text font-inter"> Upload Contract File </span>
      </label>
      <b-form-file
        id="upload_files"
        v-model="contractFile"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        plain
        :disabled="!isEditEnabled"
        hidden="true"
      />
    </div>
    <div
      v-else
      class="mt-1 p-1 text-start"
    >
      <span class="k-label font-work-sans ">Uploaded Contract File</span>
      <div class="file-uploaded mt-1">
        <div class="d-flex align-items-center">
          <kingpin-icon
            icon="FileV3Icon"
            size="28"
            class="file-icon"
          />
          <div>
            <div class="text-truncate">
              Name: {{ contractFile.name }}
            </div>
            <div> Size: {{ formatNumber(contractFile.size / 1024, 2) }} KB
            </div>
          </div>
        </div>
        <div
          class="delete-file-file d-flex align-items-center justify-content-center"
          @click="contractFile = null"
        >
          <kingpin-icon
            icon="XIcon"
            size="14"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { KFormGroup, KFormInput, KingpinIcon } from '@kingpin-global/kingpin-ui'
import { copyToClipboard, download, formatNumber } from '@/common-utils'
import { BFormFile } from 'bootstrap-vue'

export default {
  name: 'DocumentsInfo',
  components: {
    KFormGroup,
    KFormInput,
    BFormFile,
    KingpinIcon,
  },
  props: {
    userData: {
      type: Object,
      default() {
        return {}
      },
    },
    isEditEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      copyToClipboard,
      download,
      contractFile: null,
      formatNumber,
    }
  },
  watch: {
    contractFile() {
      this.$emit('updateContractFile', this.contractFile)
    },
  },
  emits: ['updateContractFile'],
}
</script>
<style lang="scss" scoped>
@import "@/@core/scss/vue/pages/kp-document-list.scss";
</style>
